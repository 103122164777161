
import { defineComponent, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import ServicoIntegracao from '@/servicos/ServicoIntegracao';
import { ETipoIntegracao } from '@/models/Enumeradores/ETipoIntegracao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import router from '@/router';
import storeSistema from '@/store/storeSistema';

export default defineComponent({
    name: 'AutenticacaoMercadoLivreIndex',
    components: {
        TituloPadrao,
    },
    props: {
    },
    setup(props) {
        const route = useRoute();
        const servicoIntegracao = new ServicoIntegracao();

        onBeforeMount(async () => {
            const codigoAutorizacao = String(route.query.code);
            if (UtilitarioGeral.valorValido(codigoAutorizacao)) {
                const retorno = await servicoIntegracao.adicionarIntegracaoMercadoLivre(codigoAutorizacao);
                const msg = storeSistema.mutations.adicionarMensagemComunicacao(retorno.status, retorno.mensagem);
                router.push({ name: 'Integracoes', query: { msg } });
            }
        });

        return {
            props,
            ETipoIntegracao,
            servicoIntegracao,
        };
    },
});
